.parameters-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px 20px 15px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 10px;
  box-shadow: 1px 10px 7px -4px rgb(0 0 0 / 50%) inset;
  border: 1px solid #080c11;
  background-color: #172533;
}

.parameters-container > * {
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  flex: 0;
}

.datetime-container {
  display: flex;
  justify-content: space-between;
}

.parameter-slider {
  margin: 10px 0px;
  width: 95%;
  --slider-track-before-thumb-height: 1px;
}

.no-event-type--body {
  padding: 0px 4em;
  margin-top: 5px;
}
