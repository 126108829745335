.sat-label-wrapper-class > div > div {
  pointer-events: none !important;
}

.sat-label-html {
  pointer-events: none;
  user-select: none;
}

.sat-circle {
  pointer-events: none;
  user-select: none;
  height: 215px;
  width: 215px;
  color: #0177fe;
}

.sat-label {
  display: block;
  border: 4px solid #0177fe;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  transition: box-shadow 0.1s ease-in-out;
}

.sat-name-pointer {
  position: absolute;
  z-index: 1;
  display: block;
  border-bottom: 4.4px solid;
  border-color: #0177fe;
  width: 44px;
  transform: translate(165px, 5px) rotate(316deg);
}

.sat-name {
  white-space: nowrap;
  transform: translate(201.5px, -67px);
  font-size: 42px;
  text-decoration: underline;
  text-underline-offset: 12px;
  text-align: left;
  transition: font-weight 0.1s ease-in-out;
}

.sat-circle.is-selected {
  color: #9fccff;
}

.sat-circle.is-selected .sat-label {
  border-color: #9fccff;
}

.sat-circle.is-selected .sat-name-pointer {
  border-color: #9fccff;
}

.sat-circle.is-state-vector {
  color: #9fff9f;
}

.sat-circle.is-state-vector .sat-label {
  border-color: #9fff9f;
}

.sat-circle.is-state-vector .sat-name-pointer {
  border-color: #9fff9f;
}

.sat-circle.is-ghost-sat {
  color: rgba(159, 204, 255, 0.5);
}

.sat-circle.is-ghost-sat .sat-label {
  border-color: rgba(159, 204, 255, 0.5);
}

.sat-circle.is-ghost-sat .sat-name-pointer {
  border-color: rgba(159, 204, 255, 0.5);
}

.sat-circle.is-hovered-active {
  color: #b6d8ff;
}

.sat-circle.is-hovered-active .sat-label {
  box-shadow: 0 0 40px 4px #b6d8ffad inset;
}

.sat-circle.is-hovered-active .sat-name-pointer {
  border-color: #b6d8ff;
}

.sat-circle.is-hovered-active .sat-name {
  text-shadow: 3px 1px 2px #474747, 2px 2px 8px #0d8edb;
}

.sat-circle.is-state-vector.is-hovered-active {
  color: #c9ffc9;
}

.sat-circle.is-state-vector.is-hovered-active .sat-label {
  box-shadow: 0 0 40px 4px #b8ffb899 inset;
}

.sat-circle.is-state-vector.is-hovered-active .sat-name-pointer {
  border-color: #c9ffc9;
}

.sat-circle.is-state-vector.is-hovered-active .sat-name {
  text-shadow: 3px 1px 2px #474747, 2px 2px 8px #14cc14;
}
